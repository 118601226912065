import React, {useState, useLayoutEffect} from "react"
import useTranslations from "../utils/useTranslations"
import Logo from "../components/logo"
import Button from "../components/button"
import LocalizedLink from "../components/localizedLink"
import imgWeb from "../images/404/ui-web.png"
import imgWebMac from "../images/404/ui-web.png"
import imgClassic from "../images/404/ui-classic.png"
import imgAndroid from "../images/homepage/ui-android.svg"
import arrowBlack from "../images/icons/arrow-black.svg"
import { ANDROID_LINK_FREE, getAndroidLink } from "../utils/getAndroidLink";

const NotFound = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)

  	const [dataOs, setOs] = useState({
  		isMac:false,
  		isAndroid:false
  	});
	const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)


  	useLayoutEffect(()=>{
  		if(window){
	  		setOs({
	  			isMac: window.gatsbyIsMac,
	  			isAndroid : window.gatsbyIsAndroid
	  		})
  		}

  		setAndroidLinkFree(getAndroidLink('free'))
  	},[])

	const badgeLink = `/images/google-play-badges/google-play-badge-${locale}.png`


	let content  = <>
		<div className="col-md-6 d-flex justify-content-between text-center flex-column not-found align-center">
			<div>
		  		<Logo productName="Web" secondaryColor="black" tag="p" />
		  		<p className="px-5 mt-4 mb-0">{t(`Download and play torrents inside your browser.`)}</p>
			</div>
			<div>
		  		<img  className="img-fluid align-self-center mt-5 flex-shrink-0 not-found-img" src={imgWeb} alt="µTorrent Web"></img>
                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center pb-1"><a id="four-o-four-win-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
				<div>
		      		<Button href="/web/compare/" id="four-o-four-win-utweb-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-win-utweb-learnmore" 
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/web/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6 d-flex justify-content-between text-center flex-column align-center">
			<div>
		  		<Logo productName="Classic" secondaryColor="black" tag="p" />
		  		<p className="px-5 mt-4 mb-0">{t(`Get the original desktop app for fast, bulk torrent downloads on Windows.`)}</p>
			</div>
			<div>
		  		<img  className="img-fluid align-self-center my-5 flex-shrink-0 not-found-img" src={imgClassic} alt="µTorrent Classic"></img>
				<div>
		      		<Button href="/desktop/compare/" id="four-o-four-win-classic-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-win-classic-learnmore"  
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/desktop/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
		</div>
	</>

  	if(dataOs.isMac){
  		content = <>
			<div className="offset-md-3 col-md-6 d-flex justify-content-between text-center flex-column align-center">
	      		<Logo productName="Web"  secondaryColor="black" tag="p" />
	      		<p className="px-3 mt-4 mb-0">{t(`Download and play torrent files inside your browser.`)}</p>
	      		<img  className="img-fluid align-self-center mt-5 flex-shrink-0 not-found-img" src={imgWebMac} alt="µTorrent Web"></img>
                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center"><a id="four-o-four-mac-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
				<div>
		      		<Button href="/web/downloads/complete/track/stable/os/mac/" id="four-o-four-mac-utweb-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-mac-utweb-learnmore"  
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/web/mac/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
  		</>
  	}
  	else if(dataOs.isAndroid){
  		content = <>
			<div className="offset-md-3 col-md-6 d-flex justify-content-start text-center flex-column align-center">
	      		<Logo productName="Android" secondaryColor="black" tag="p" />
	      		<p className="px-md-5 mt-4 mb-0">{t("Download torrents with the #1 Android torrent app on Google Play.")}</p>
				<div>
					<a id="four-o-four-android-dl" className="pointer" href={androidLinkFree}>
						<img className="img-fluid google-play-button" src={badgeLink} alt="µTorrent Android Google Play"></img>
					</a>
					<div>
						<LocalizedLink
						id="four-o-four-android-learnmore"
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/mobile/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
	      		<img  className="img-fluid align-self-center my-5 flex-shrink-0 android-hero" src={imgAndroid} alt="µTorrent Web"></img>
			</div>

  		</>

  	}

	return(
		<div className="notFound">
			<div className="container">
				<div className="row my-5 py-3">
					<div className="col-12 text-center mt-4 mb-3">
						<p className="h1">{t(`Sorry... We couldn't find that page.`)}</p>
						<p className="not-found-subheader px-1">{t(`The <a class='text-dark' href='/'><b><u>homepage</u></b></a> is a good place to start your search, or you might like to try:`)}</p>
					</div>
					{content}
				</div>
			</div>
		</div>
	)

}

export default NotFound
